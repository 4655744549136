import React, { useEffect, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

export default function DynamicTable({ data, columns, loading }) {
  /**
   * data = es el array de datos que iran en la tabla
   * columns = son las columnas con los datos necesarios para renderizar
   * loading = es el estado para que aparezca el spinner en la tabla
   */
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [paginatorTemplate, setPaginatorTemplate] = useState(
    "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
  );

  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        sortable={col.sortable}
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
      />
    );
  });

  /** FILTER */
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  /** END FILTERS */

  /** RESPONSIVE PAGINATOR */
  const responsivePaginator = () => {
    if (window.screen.width <= "400") {
      setPaginatorTemplate(
        "CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
      );
    } else {
      setPaginatorTemplate(
        "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      );
    }
  };

  /** DETECT RESIZE */
  window.addEventListener("resize", () => responsivePaginator());

  useEffect(() => {
    responsivePaginator();
    initFilters();
  }, []);

  const header = renderHeader();

  return (
    <DataTable
      value={data}
      paginator
      showGridlines
      rows={10}
      filters={filters}
      header={header}
      loading={loading}
      responsiveLayout="stack"
      breakpoint="960px"
      paginatorTemplate={paginatorTemplate}
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    >
      {dynamicColumns}
    </DataTable>
  );
}
