import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/Utils/LoadingSpinner";
import AuthContext from "../../context/AuthContext";
import RegisterGoogleUser from "../../components/Auth/RegisterGoogleUser";

export default function GoogleOAuth() {
  const [searchParams] = useSearchParams(); //obtenemos los parametros de la url
  const { googleCallbackQuery, responseLoginGoogle } = useContext(AuthContext);

  useEffect(() => {
    googleCallbackQuery(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (responseLoginGoogle?.need_register) {
    return <RegisterGoogleUser />;
  }

  return (
    <div className="text-center mt-5">
      <div className="mb-5">Verificando datos...</div>
      <LoadingSpinner />
    </div>
  );
}
