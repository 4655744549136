import LoadingSpinner from "./LoadingSpinner";

export default function LoadingPage() {
  return (
    <div
      className="flex justify-content-center align-items-center h-screen"
    >
      <LoadingSpinner />
    </div>
  );
}
