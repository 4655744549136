import { useState } from "react";

/**
 * CUSTOM HOOK QUE NOS PERMITE SETAR DATOS DE UN FORMULARIO A UN ESTADO
 *
 * */

export default function useForm(initialValues, initialFormErrors) {
  // Estado donde se guardara todos los valores del formulario
  const [formData, setFormData] = useState(initialValues);
  // Estado donde se guardaran los errores de cada input del formulario
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  // Estado donde se almacenaran otros errores
  const [moreFormErrors, setMoreFormErrors] = useState([]);

  /** FUNCION PARA SETEAR PROPIEDADES EN FORMDATA */
  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    // seteamos el valor en input correspondiente
    setFormData({ ...formData, [name]: value });
    // quitamos el msg de error en input
    setFormErrors({ ...formErrors, [name]: null });
    // quitamos los demas errores
    setMoreFormErrors([]);
  };

  /** DEJA EN VALORES INICIALES A LOS ESTADOS */
  const resetForm = (setPageFormData) => {
    // Limpiamos el form data de mi pagina (que se setea en accion de nuevo o editar)
    setPageFormData(initialValues);
    // Limpiamos los estados de nuestro hook
    setFormData(initialValues);
    setFormErrors(initialFormErrors);
    setMoreFormErrors([]);
  };

  return {
    handleFormChange,
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    moreFormErrors,
    setMoreFormErrors,
    resetForm,
  };
}
