import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export default function LogoutDialog(props) {
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => props.setDisplayLogout(false)}
          className="p-button-text"
        />
        <Button
          loading={props.loading}
          label="Si"
          icon="pi pi-check"
          onClick={() => props.logoutQuery()}
          autoFocus
        />
      </div>
    );
  };

  return (
    <Dialog
      className="logoutDialog"
      position={"top"}
      draggable={false}
      header="Cerrar Sesion"
      visible={props.displayLogout}
      footer={renderFooter("displayBasic")}
      onHide={() => props.setDisplayLogout(false)}
    >
      <p>Esta seguro/a de cerrar sesion?</p>
    </Dialog>
  );
}
