import { createContext, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

const ToastMessageContext = createContext();

const ToastMessageProvider = ({ children }) => {
  const toast = useRef(null);
  const [blockedDocument, setBlockedDocument] = useState(false);

  const showSuccess = (detail) => {
    toast.current.show({
      severity: "success",
      summary: "Realizado!",
      detail: detail,
      life: 15000,
    });
  };

  const showInfo = (detail) => {
    toast.current.show({
      severity: "info",
      summary: "Informacion!",
      detail: detail,
      life: 15000,
    });
  };

  const showWarn = (detail) => {
    toast.current.show({
      severity: "warn",
      summary: "Advertencia!",
      detail: detail,
      life: 15000,
    });
  };

  const showError = (detail) => {
    toast.current.show({
      severity: "error",
      summary: "Error!",
      detail: detail,
      life: 15000,
    });
  };

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
    showSuccess("Texto copiado al portapapeles");
  };

  /** Loaders con fondo bloqueado */
  const showBackdropLoader = () => {
    setBlockedDocument(true);
  };

  const hideBackdropLoader = () => {
    setBlockedDocument(false);
  };

  const data = {
    showSuccess,
    showInfo,
    showWarn,
    showError,
    copyToClipBoard,
    showBackdropLoader,
    hideBackdropLoader,
  };

  return (
    <ToastMessageContext.Provider value={data}>
      <Toast ref={toast} />
      <BlockUI
        blocked={blockedDocument}
        fullScreen
        template={<ProgressSpinner />}
      />
      {children}
    </ToastMessageContext.Provider>
  );
};

export { ToastMessageProvider };

export default ToastMessageContext;
