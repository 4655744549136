import api from "../utils/api";
import { useContext } from "react";
import ToastMessageContext from "../context/ToastMessageContext";

export default function useDecryptData() {
  const { showBackdropLoader, hideBackdropLoader } =
    useContext(ToastMessageContext);

  const getDecryptData = async (value, onSuccess, moreData) => {
    try {
      showBackdropLoader();
      const { data } = await api.post("accounts/decrypt-data", {
        data: value,
      });

      // Ejecutamos la funcion success que le pasemos
      onSuccess(data, moreData);
    } catch (error) {
      console.error(error);
    } finally {
      hideBackdropLoader();
    }
  };

  return {
    getDecryptData,
  };
}
