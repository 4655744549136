import { useEffect, useState, useContext } from "react";
import API from "../../utils/api";
import { getErrorsStatus422 } from "../../utils/errorsHandling";
import ToastMessageContext from "../../context/ToastMessageContext";
import FormErrorMessage from "../Utils/FormErrorMessage";
import MoreFormErrors from "../Utils/MoreFormErrors";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import useForm from "../../hooks/useForm";

/** STATIC VALUES */
const initialValues = {
  account_name: "",
  user: "",
  password: "",
  more_details: "",
};

const initialFormErrors = {
  account_name: null,
  user: null,
  password: null,
  more_details: null,
};
/** END STATIC VALUES */

export default function AccountForm(props) {
  /** CUSTOM FORM HOOK */
  const {
    handleFormChange,
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    moreFormErrors,
    setMoreFormErrors,
    resetForm,
  } = useForm(initialValues, initialFormErrors);

  const { showSuccess, showError } = useContext(ToastMessageContext);
  const [loadingForm, setLoadingForm] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    let _formErrors = formErrors;
    if (formData.account_name === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        account_name: "Campo requerido",
      };
    }

    if (formData.user === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        user: "Campo requerido",
      };
    }

    if (formData.password === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        password: "Campo requerido",
      };
    }

    setFormErrors(_formErrors);

    return formIsValid;
  };

  /** ----QUERYS---- */
  const createQuery = () => {
    setLoadingForm(true);
    API.post("accounts/create", formData)
      .then((res) => {
        showSuccess(res.data.message);
        onSuccessForm();
      })
      .catch((err) => {
        const errors = err.response;
        if (errors.status === 422) {
          const errors422 = getErrorsStatus422(errors.data.errors);
          setMoreFormErrors(errors422);
        } else {
          showError(err.message);
        }
      })
      .then(() => {
        setLoadingForm(false);
      });
  };

  const updateQuery = () => {
    setLoadingForm(true);
    API.put(`accounts/update/${formData.id}`, formData)
      .then((res) => {
        showSuccess(res.data.message);
        onSuccessForm();
      })
      .catch((err) => {
        const errors = err.response;
        if (errors.status === 422) {
          const errors422 = getErrorsStatus422(errors.data.errors);
          setMoreFormErrors(errors422);
        } else {
          showError(err.message);
        }
      })
      .then(() => {
        setLoadingForm(false);
      });
  };
  /** ----END QUERYS---- */

  const create = (event) => {
    event.preventDefault();
    if (validateForm()) {
      createQuery();
    }
  };

  const update = (event) => {
    event.preventDefault();
    if (validateForm()) {
      updateQuery();
    }
  };

  const onSuccessForm = () => {
    props.setDisplayForm(false);
    props.getAccounts();
  };

  const onHideForm = () => {
    props.setDisplayForm(false);
    resetForm(props.setPageFormData);
  };

  useEffect(() => {
    if (props.pageFormData) {
      setFormData(props.pageFormData);
    } else {
      setFormData(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageFormData]);

  return (
    <Dialog
      className="formDialog"
      header={props.formTitle}
      visible={props.displayForm}
      onHide={onHideForm}
    >
      <form
        className="p-fluid grid"
        onSubmit={formData.id ? update : create}
        autoComplete="off"
      >
        <div className="field col-12 mt-4">
          <span className="p-float-label">
            <InputText
              className={`w-full ${formErrors.account_name && "p-invalid"}`}
              name="account_name"
              value={formData.account_name}
              onChange={handleFormChange}
            />
            <label>Nombre de cuenta</label>
          </span>
          <FormErrorMessage inputName="account_name" formErrors={formErrors} />
        </div>

        <div className="field col-12 md:col-6 mt-4">
          <span className="p-float-label">
            <InputText
              className={`w-full ${formErrors.user && "p-invalid"}`}
              name="user"
              value={formData.user}
              onChange={handleFormChange}
            />
            <label>Usuario</label>
          </span>
          <FormErrorMessage inputName="user" formErrors={formErrors} />
        </div>

        <div className="field col-12 md:col-6 mt-4">
          <span className="p-float-label">
            <Password
              className={`w-full ${formErrors.password && "p-invalid"}`}
              name="password"
              value={formData.password}
              onChange={handleFormChange}
              toggleMask
              feedback={false}
            />
            <label>Contraseña</label>
          </span>
          <FormErrorMessage inputName="password" formErrors={formErrors} />
        </div>

        <div className="field col-12 mt-4">
          <span className="p-float-label">
            <InputTextarea
              className={`w-full ${formErrors.more_details && "p-invalid"}`}
              name="more_details"
              value={formData.more_details || ""}
              onChange={handleFormChange}
              rows={5}
            />
            <label>Mas detalles</label>
          </span>
          <FormErrorMessage inputName="more_details" formErrors={formErrors} />
        </div>

        <MoreFormErrors errors={moreFormErrors} />

        <div className="field flex justify-content-end p-2 mt-4 w-full">
          <Button
            type="submit"
            loading={loadingForm}
            label="Guardar"
            className="p-button-primary w-auto"
          />
        </div>
      </form>
    </Dialog>
  );
}
