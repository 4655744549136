import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import SeoTags from "../components/SeoTags";

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <>
    <SeoTags pageTitle={"Not Found"} pageDescription={"Pagina no encontrada"}/>
      <div className="flex align-items-center justify-content-center flex-column">
        <h3 className="codeTitle">404</h3>
        <p className="description">Pagina no encontrada</p>
        <Button label="Volver al Inicio" onClick={() => navigate("/")} />
      </div>
    </>
  );
}
