import { Link } from "react-router-dom";
import RegisterForm from "../../components/Auth/RegisterForm";
import SeoTags from "../../components/SeoTags";

export default function Register() {
  return (
    <>
      <SeoTags
        pageTitle={"Registrarse"}
        pageDescription={"Pagina para Registrarse"}
      />
      <div className="flex align-items-center justify-content-center mt-5 mb-5">
        <div className="surface-card p-4 shadow-2 border-round w-full md:w-5 m-5">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Registrarse
            </div>
          </div>
          <RegisterForm />
          <div className="text-center mt-5 mb-5">
            <span className="text-600 font-medium line-height-3">
              Ya tenes cuenta?
            </span>
            <Link
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              to="/auth/login"
            >
              Ir al login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
