import { Routes, Route } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
import Accounts from "../pages/Accounts/Accounts";
import AccountsImport from "../pages/Accounts/AccountsImport";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import GoogleOAuth from "../pages/Auth/GoogleOAuth";
import MyAccount from "../pages/MyAccount";
import PrivateRoutes from "./PrivateRoutes";
import AuthRoutes from "./AuthRoutes";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />

      <Route path="/auth" element={<AuthRoutes />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="google-oauth" element={<GoogleOAuth />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Accounts />} />
        <Route path="/accounts/import" element={<AccountsImport />} />
        <Route path="/my-account" element={<MyAccount />} />
      </Route>
    </Routes>
  );
}
