import { Panel } from "primereact/panel";
import SeoTags from "../../components/SeoTags";
import AccountImportForm from "../../components/Accounts/AccountImportForm";

export default function AccountsImport() {
  return (
    <>
      <SeoTags
        pageTitle={"Importar Cuentas"}
        pageDescription={"Pagina de Importar Cuentas"}
      />
      <Panel header="Importar Cuentas" className="mt-3">
        <div className="mb-2">
          <a
            rel="noreferrer"
            target="_blank"
            download
            className="no-underline"
            href={`${process.env.PUBLIC_URL}/excel_examples/prueba_accounts.xlsx`}
          >
            Descargar ejemplo
          </a>
        </div>

        <AccountImportForm />
      </Panel>
    </>
  );
}
