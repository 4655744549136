import axios from 'axios';
import jwt_decode from "jwt-decode";

const API = () => {

    const defaultOptions = {
        baseURL: `${process.env.REACT_APP_API_URL}/`
    };

    // Creamos instancia
    let instance = axios.create(defaultOptions);

    // Seteamos el AUTH token a la cabecera para cada peticion, debe ser async para poder hacer la peticion refresh token
    instance.interceptors.request.use(async function (config) {

        let token = getToken();

        if (token) {
            try {
                // Decodificamos el token
                const decoded = jwt_decode(token);

                const actualDate = new Date();
                const expirationDate = new Date(decoded.exp * 1000);
                const diffInSeconds = (expirationDate - actualDate) / 1000;

                // Si me quedan 5 min o menos para que expire el token, lo refresco
                if (diffInSeconds <= 300) {
                    await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then((res) => {
                        // Añadimos al localStorage el nuevo token
                        token = res.data.access_token;
                        setToken(token);
                    }).catch(() => {
                        // Si ocurre algun error (el token expiro o no coincide en nuestro backend), redireccionamos al login y limpiamos el token
                        removeToken();
                        return window.location = `${process.env.REACT_APP_DOMAIN_URL}/auth/login`;

                    });
                }

                // Añadimos a la cabecera Authorization
                config.headers.Authorization = `Bearer ${token}`;

            } catch (error) {
                // Si esta mal formado el token, limpiamos y redirigimos a login
                removeToken();
                return window.location = `${process.env.REACT_APP_DOMAIN_URL}/auth/login`;
            }

        }

        return config;
    });

    // Capturamos respuestas con interceptor
    instance.interceptors.response.use(function (response) {
        // Retornamos las respuestas, no hace falta hacer nada
        return response;
    }, function (error) {
        const errorResponse = error.response;
        // Unauthorized
        if (errorResponse.status === 401) {
            removeToken();
            return window.location = `${process.env.REACT_APP_DOMAIN_URL}/auth/login`;
        }
        // Si es un codigo diferente al 401, retornamos los errores
        return Promise.reject(error);
    });

    return instance;
};

const API_NO_AUTH = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/`
});

const getDecodedToken = () => {
    try {
        const token = getToken();
        const decoded = jwt_decode(token);
        return decoded;
    } catch (error) {
        return null;
    }
}

const getToken = () => {
    return localStorage.getItem('token');
}

const setToken = (token) => {
    localStorage.setItem("token", token);
}

const removeToken = () => {
    localStorage.removeItem("token");
}

export { API_NO_AUTH, getToken, setToken, removeToken, getDecodedToken };

export default API();
