import { Button } from "primereact/button";
import { API_NO_AUTH } from "../../utils/api";
import { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Utils/LoadingSpinner";
import { setToken } from "../../utils/api";
import { useNavigate } from "react-router-dom";

export default function RegisterGoogleUser() {
  const { responseLoginGoogle, updateUserData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const register = async () => {
    try {
      setLoading(true);
      const { data } = await API_NO_AUTH.post("auth/register-google-user", {
        token: responseLoginGoogle.token,
      });
      updateUserData(data.user);
      setToken(data.access_token);
      navigate("/");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="mb-5">Verificando datos...</div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flex justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full md:w-5 m-5">
        Desea registrarse con su cuenta de google?
        <div className="flex justify-content-center gap-2">
          <Button
            className="mt-3 p-button-sm"
            label="Aceptar"
            onClick={register}
          />
          <Link to="/auth/login">
            <Button
              className="mt-3 p-button-sm p-button-danger"
              label="Cancelar"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
