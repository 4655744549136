import MyAccountForm from "../components/MyAccount/MyAccountForm";
import { Panel } from "primereact/panel";
import LoadingSpinner from "../components/Utils/LoadingSpinner";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SeoTags from "../components/SeoTags";

export default function MyAccount() {
  const { userData } = useContext(AuthContext);

  return (
    <>
      <SeoTags
        pageTitle={"Mi Cuenta"}
        pageDescription={"Pagina de Mi Cuenta"}
      />
      <Panel header="Mi Cuenta" className="mt-3">
        {!userData && <LoadingSpinner />}
        {userData &&
          (userData.external_auth ? (
            <div className="mt-5 mb-5">
              Esta cuenta obtiene los datos de {userData.external_auth}.
            </div>
          ) : (
            <MyAccountForm formData={userData} />
          ))}
      </Panel>
    </>
  );
}
