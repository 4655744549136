import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import API from "../../utils/api";
import ToastMessageContext from "../../context/ToastMessageContext";
import SpanPassword from "../../components/Utils/SpanPassword";
import DynamicTable from "../../components/Utils/DynamicTable";
import ActionsToolbar from "../../components/ActionsToolbar";
import AccountForm from "../../components/Accounts/AccountForm";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import SeoTags from "../../components/SeoTags";
import { downloadExcelFile } from "../../utils/downloadUtils";
import useDecryptData from "../../hooks/useDecryptData";
export default function Accounts() {
  const navigate = useNavigate();

  const { showSuccess, showError, copyToClipBoard } =
    useContext(ToastMessageContext);
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [pageFormData, setPageFormData] = useState(null);
  /** USE DECRYPT DATA */
  const { getDecryptData } = useDecryptData();

  function onSuccessDecrypt(data, moreData) {
    const newObJ = { ...moreData, password: data };
    setPageFormData(newObJ);
    setFormTitle("Editar");
    setDisplayForm(true);
  }
  /** END USE DECRYPT DATA */

  /** QUERYS */
  const exportQuery = () => {
    setLoadingExport(true);
    API.get("accounts/export", { responseType: "blob" })
      .then((res) => {
        downloadExcelFile(res.data, "accounts.xlsx");
      })
      .catch((err) => {
        showError(err.message);
      })
      .then(() => {
        setLoadingExport(false);
      });
  };

  const getAccountsQuery = () => {
    setLoadingAccounts(true);
    API.get("accounts/getAll")
      .then((res) => {
        setAccounts(res.data);
      })
      .catch((err) => {
        showError(err.message);
      })
      .then(() => {
        setLoadingAccounts(false);
      });
  };

  const deleteAccountQuery = (id) => {
    setLoadingAccounts(true);
    API.delete(`accounts/delete/${id}`)
      .then((res) => {
        showSuccess(res.data.message);
      })
      .catch((err) => {
        showError(err.message);
      })
      .then(() => {
        getAccountsQuery();
      });
  };
  /** END QUERYS */

  /** TABLE TEMPLATES */
  const userBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-column">
        <span>
          <span className="mr-2 ml-2 text"> {rowData.user} </span>
          <i
            className="cursor-pointer pi pi-copy"
            onClick={() => copyToClipBoard(rowData.user)}
          />
        </span>
      </div>
    );
  };

  const passwordBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-column">
        <SpanPassword value={rowData.password} />
      </div>
    );
  };

  const actionButtons = (rowData) => {
    return (
      <div className="actionButtons">
        <Button
          icon="pi pi-pencil"
          className="p-button-sm p-button-warning mr-2 mt-2"
          onClick={() => btnEditAction(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-sm p-button-danger mt-2"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };
  /** END TABLE TEMPLATES */

  /** COLUMNAS A RENDERIZAR EN TABLA */
  const columns = [
    {
      field: "account_name",
      header: "Cuenta",
      body: false,
      sortable: true,
    },
    {
      field: "user",
      header: "Usuario",
      body: userBodyTemplate,
      sortable: true,
    },
    {
      field: "password",
      header: "Contraseña",
      body: passwordBodyTemplate,
      sortable: false,
    },
    {
      field: "",
      header: "Acciones",
      body: actionButtons,
      sortable: false,
    },
  ];

  /** END COLUMNS */

  /** BTN ACTIONS */
  const btnExportAction = () => {
    exportQuery();
  };

  const btnImportAction = () => {
    navigate("/accounts/import");
  };

  const btnNewAction = () => {
    setPageFormData(null);
    setFormTitle("Nuevo");
    setDisplayForm(true);
  };

  const btnEditAction = (data) => {
    getDecryptData(data.password, onSuccessDecrypt, data);
  };

  const confirmDelete = (data) => {
    confirmDialog({
      message: "Esta seguro de eliminar el registro?",
      header: "Eliminar",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteAccountQuery(data.id),
    });
  };

  /** END BTN ACTIONS */

  useEffect(() => {
    getAccountsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SeoTags pageTitle={"Cuentas"} pageDescription={"Pagina de Cuentas"} />
      <ConfirmDialog />

      <AccountForm
        pageFormData={pageFormData}
        setPageFormData={setPageFormData}
        formTitle={formTitle}
        displayForm={displayForm}
        getAccounts={getAccountsQuery}
        setDisplayForm={setDisplayForm}
      />
      <ActionsToolbar
        btnNewAction={btnNewAction}
        btnImportAction={btnImportAction}
        btnExportAction={btnExportAction}
        loadingExport={loadingExport}
      />
      <Panel header="Cuentas" className="mt-3">
        <DynamicTable
          data={accounts}
          columns={columns}
          loading={loadingAccounts}
        />
      </Panel>
    </>
  );
}
