import { useContext, useState } from "react";
import FormErrorMessage from "../Utils/FormErrorMessage";
import MoreFormErrors from "../Utils/MoreFormErrors";
import API from "../../utils/api";
import { getErrorsStatus422 } from "../../utils/errorsHandling";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import ToastMessageContext from "../../context/ToastMessageContext";
import useForm from "../../hooks/useForm";

/** STATIC VALUES */
const initialValues = {
  password: "",
  password_confirmation: "",
};

const initialFormErrors = {
  password: null,
  password_confirmation: null,
};
/** END STATIC VALUES */

export default function ChangePassword() {
  /** CUSTOM FORM HOOK */
  const {
    handleFormChange,
    formData,
    formErrors,
    setFormErrors,
    moreFormErrors,
    setMoreFormErrors,
    resetForm
  } = useForm(initialValues, initialFormErrors);

  const { showSuccess, showError } = useContext(ToastMessageContext);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    let _formErrors = formErrors;
    if (formData.password === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        password: "Campo requerido",
      };
    }

    if (formData.password_confirmation === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        password_confirmation: "Campo requerido",
      };
    }

    setFormErrors(_formErrors);

    return formIsValid;
  };

  /** ----QUERYS---- */
  const changePasswordQuery = () => {
    setLoadingForm(true);
    API.put("auth/changePassword", formData)
      .then((res) => {
        showSuccess(res.data.message);
        onHide();
      })
      .catch((err) => {
        const errors = err.response;
        if (errors.status === 422) {
          const errors422 = getErrorsStatus422(errors.data.errors);
          setMoreFormErrors(errors422);
        } else {
          showError(err.message);
        }
      })
      .then(() => {
        setLoadingForm(false);
      });
  };
  /** ----END QUERYS---- */

  const changePassword = (e) => {
    e.preventDefault();
    if (validateForm()) {
      changePasswordQuery();
    }
  };

  const onHide = () => {
    resetForm();
    setDisplayDialog(false);
  };

  return (
    <>
      <Dialog
        className="formDialogSmall"
        header="Cambiar contraseña"
        visible={displayDialog}
        onHide={onHide}
      >
        <form className="p-fluid grid" autoComplete="off">
          <div className="field col-12 mt-4">
            <span className="p-float-label">
              <Password
                className={`w-full ${formErrors.password && "p-invalid"}`}
                name="password"
                value={formData.password}
                onChange={handleFormChange}
                feedback={false}
                toggleMask
              />
              <label>Contraseña</label>
            </span>
            <FormErrorMessage inputName="password" formErrors={formErrors} />
          </div>

          <div className="field col-12 mt-4">
            <span className="p-float-label">
              <Password
                className={`w-full ${
                  formErrors.password_confirmation && "p-invalid"
                }`}
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleFormChange}
                feedback={false}
                toggleMask
              />
              <label>Confirmar Contraseña</label>
            </span>
            <FormErrorMessage
              inputName="password_confirmation"
              formErrors={formErrors}
            />
          </div>

          <MoreFormErrors errors={moreFormErrors} />

          <div className="field flex justify-content-end p-2 mt-4 w-full">
            <Button
              type="button"
              onClick={changePassword}
              loading={loadingForm}
              label="Guardar"
              className="p-button-primary w-auto"
            />
          </div>
        </form>
      </Dialog>

      <div className="field flex justify-content-end p-2 mt-4">
        <Button
          type="button"
          onClick={() => setDisplayDialog(true)}
          label="Cambiar contraseña"
          icon="pi pi-lock"
          className="p-button-sm p-button-info"
        />
      </div>
    </>
  );
}
