
import { Link } from "react-router-dom";
import ForgotPasswordForm from "../../components/Auth/ForgotPasswordForm";
import SeoTags from "../../components/SeoTags";

export default function ForgotPassword() {

  return (
    <>
      <SeoTags
        pageTitle={"Reestablecer contraseña"}
        pageDescription={"Pagina para reestablecer contraseña de usuario"}
      />
      <div className="flex align-items-center justify-content-center mt-5 mb-5">
        <div className="surface-card p-4 shadow-2 border-round w-full md:w-5 m-5">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium">
              Restablezca su contraseña
            </div>
          </div>
          <ForgotPasswordForm />
          <div className="text-center mt-2 mb-5">
            <Link
              className="font-medium no-underline text-blue-500 cursor-pointer"
              to="/auth/login"
            >
              Ir al login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
