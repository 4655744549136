import { useState, useContext } from "react";
import { API_NO_AUTH } from "../../utils/api";
import { getErrorsStatus422 } from "../../utils/errorsHandling";
import ToastMessageContext from "../../context/ToastMessageContext";
import FormErrorMessage from "../Utils/FormErrorMessage";
import MoreFormErrors from "../Utils/MoreFormErrors";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import useForm from "../../hooks/useForm";

/** STATIC VALUES */
const initialValues = {
  email: "",
};

const initialFormErrors = {
  email: null,
};
/** END STATIC VALUES */

export default function ForgotPasswordForm() {
   /** CUSTOM FORM HOOK */
   const {
    handleFormChange,
    formData,
    formErrors,
    setFormErrors,
    moreFormErrors,
    setMoreFormErrors
  } = useForm(initialValues, initialFormErrors);

  const { showError } = useContext(ToastMessageContext);
  const [loadingForm, setLoadingForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const validateForm = () => {
    let formIsValid = true;
    let _formErrors = formErrors;

    if (formData.email === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        email: "Campo requerido",
      };
    }

    setFormErrors(_formErrors);
    return formIsValid;
  };

  /** ----QUERYS---- */
  const forgotPassQuery = () => {
    setLoadingForm(true);
    API_NO_AUTH.post("auth/forgot-password", formData)
      .then((res) => {
        setSuccessMessage(res.data.message);
      })
      .catch((err) => {
        const errors = err.response;
        if (errors.status === 422) {
          const errors422 = getErrorsStatus422(errors.data.errors);
          setMoreFormErrors(errors422);
        } else {
          showError(err.message);
        }
      })
      .then(() => {
        setLoadingForm(false);
      });
  };
  /** ----END QUERYS---- */

  const forgotPass = (e) => {
    e.preventDefault();
    if (validateForm()) {
      forgotPassQuery();
    }
  };
  return (
    <>
      {successMessage && (
        <div className="col-12 mb-4">
          <Message
            severity="success"
            text={successMessage}
          />
        </div>
      )}

      {!successMessage && (
        <form className="p-fluid grid" autoComplete="off" onSubmit={forgotPass}>
          <div className="field col-12 m-0">
            Le enviaremos un enlace para poder reestablecer su contraseña.
          </div>
          <div className="field col-12 mt-3">
            <span className="p-float-label">
              <InputText
                className={`w-full ${formErrors.email && "p-invalid"}`}
                name="email"
                onChange={handleFormChange}
                value={formData.email}
              />
              <label>Email</label>
            </span>
            <FormErrorMessage inputName="email" formErrors={formErrors} />
          </div>

          <MoreFormErrors errors={moreFormErrors} />

          <div className="field p-2 mt-4 w-full">
            <Button
              type="submit"
              loading={loadingForm}
              label="Enviar enlace"
              icon="pi pi-envelope"
              className="w-full"
            />
          </div>
        </form>
      )}
    </>
  );
}
