import { ProgressSpinner } from "primereact/progressspinner";

export default function LoadingSpinner() {
  return (
    <div className="text-center">
      <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth="3"
        animationDuration=".5s"
      />
    </div>
  );
}
