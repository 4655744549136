import React, { useState, useContext } from "react";
import API from "../../utils/api";
import { getErrorsStatus422 } from "../../utils/errorsHandling";
import ToastMessageContext from "../../context/ToastMessageContext";
import AuthContext from "../../context/AuthContext";
import ChangePassword from "./ChangePassword";
import MoreFormErrors from "../Utils/MoreFormErrors";
import FormErrorMessage from "../Utils/FormErrorMessage";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useForm from "../../hooks/useForm";

/** STATIC VALUES */
const initialFormErrors = {
  name: null,
  email: null,
};
/** END STATIC VALUES */

export default function MyAccountForm(props) {
  /** CUSTOM FORM HOOK */
  const {
    handleFormChange,
    formData,
    formErrors,
    setFormErrors,
    moreFormErrors,
    setMoreFormErrors
  } = useForm(props.formData, initialFormErrors);

  const { showSuccess, showError } = useContext(ToastMessageContext);
  const { updateUserData } = useContext(AuthContext);
  const [loadingForm, setLoadingForm] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    let _formErrors = formErrors;
    if (formData.name === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        name: "Campo requerido",
      };
    }

    if (formData.email === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        email: "Campo requerido",
      };
    }

    setFormErrors(_formErrors);

    return formIsValid;
  };

  /** ----QUERYS---- */
  const editAccountQuery = () => {
    setLoadingForm(true);
    API.put("auth/editAccount", formData)
      .then((res) => {
        updateUserData(res.data.user);
        showSuccess(res.data.message);
      })
      .catch((err) => {
        const errors = err.response;
        if (errors.status === 422) {
          const errors422 = getErrorsStatus422(errors.data.errors);
          setMoreFormErrors(errors422);
        } else {
          showError(err.message);
        }
      })
      .then(() => {
        setLoadingForm(false);
      });
  };
  /** ----END QUERYS---- */

  const editAccount = (event) => {
    event.preventDefault();
    if (validateForm()) {
      editAccountQuery();
    }
  };

  return (
    <form className="p-fluid grid" autoComplete="off" onSubmit={editAccount}>
      <div className="field col-12 md:col-6 mt-4">
        <span className="p-float-label">
          <InputText
            className={`w-full ${formErrors.name && "p-invalid"}`}
            name="name"
            value={formData.name}
            onChange={handleFormChange}
          />
          <label>Nombre y Apellido</label>
        </span>
        <FormErrorMessage inputName="name" formErrors={formErrors} />
      </div>

      <div className="field col-12 md:col-6 mt-4">
        <span className="p-float-label">
          <InputText
            className={`w-full ${formErrors.email && "p-invalid"}`}
            name="email"
            value={formData.email}
            onChange={handleFormChange}
          />
          <label>Email</label>
        </span>
        <FormErrorMessage inputName="email" formErrors={formErrors} />
      </div>

      <ChangePassword />

      <MoreFormErrors errors={moreFormErrors} />

      <div className="field flex justify-content-end p-2 mt-4 w-full">
        <Button
          type="submit"
          loading={loadingForm}
          label="Guardar"
          className="p-button-primary w-auto"
        />
      </div>
    </form>
  );
}
