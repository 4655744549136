import { useContext } from "react";
import { useState } from "react";
import ToastMessageContext from "../../context/ToastMessageContext";
import useDecryptData from "../../hooks/useDecryptData";

export default function SpanPassword({ value }) {
  const [decrypted, setDecrypted] = useState(null);
  const { copyToClipBoard } = useContext(ToastMessageContext);
  const [hidePassword, setHidePassword] = useState(true);

  /** USE DECRYPT DATA */
  const { getDecryptData } = useDecryptData();

  function onSuccessShow(data) {
    setDecrypted(data);
    setHidePassword(false);
  }

  function onSuccessCopy(data) {
    setDecrypted(data);
    copyToClipBoard(data);
  }
  /** END USE DECRYPT DATA */

  const handleClickEye = () => {
    if (!decrypted) {
      getDecryptData(value, onSuccessShow);
      return;
    }

    // Sino solo muestro valor
    setHidePassword(!hidePassword);
  };

  const handleClickCopy = () => {
    if (!decrypted) {
      getDecryptData(value, onSuccessCopy);
      return;
    }

    // Sino solo copio valor
    copyToClipBoard(decrypted);
  };

  return (
    <span>
      <span className="mr-2 ml-2"> {hidePassword ? "••••••" : decrypted} </span>
      <i
        className={`mr-2 cursor-pointer font-bold ${
          hidePassword ? "pi pi-eye" : "pi pi-eye-slash"
        }`}
        onClick={handleClickEye}
      />
      <i className="ml-2 cursor-pointer pi pi-copy" onClick={handleClickCopy} />
    </span>
  );
}
