import { useContext } from "react";
import { Outlet } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import LoadingPage from "../Utils/LoadingPage";
import Navbar from "../Navbar";

export default function DashboardLayout() {

  const { userData } = useContext(AuthContext);

  if (!userData) {
    return <LoadingPage />;
  }

  return (
    <>
      <Navbar />
      <div className="md:m-5 m-2">
        <Outlet />
      </div>
    </>
  );
}
