import React, { useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import logo from "../assets/img/logo.svg";

export default function Navbar() {
  const navigate = useNavigate();

  const { logout, userData } = useContext(AuthContext);
  const menu = useRef(null);

  const itemsMyAccount = [
    {
      label: `Hola! ${userData.name}`,
      template: (item) => {
        return (
          /* custom element */
          <strong className="text-sm welcomeMsg">{item.label}</strong>
        );
      },
    },
    {
      separator: true,
    },
    {
      label: "Mi Cuenta",
      icon: "pi pi-user",
      command: () => {
        navigate("/my-account");
      },
    },
    {
      label: "Cerrar Sesión",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        logout();
      },
    },
  ];

  const start = (
    <div className="logo">
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
    </div>
  );

  const end = (
    <div>
      <Menu model={itemsMyAccount} popup ref={menu} id="popup_menu" />
      <Avatar
        style={{ backgroundColor: "#4caf4f", color: "#ffffff" }}
        image={userData.avatar}
        label={!userData.avatar ? userData.avatar_label : null}
        className="mr-2"
        size="large"
        shape="circle"
        onClick={(event) => menu.current.toggle(event)}
      />
    </div>
  );

  return (
    <div className="card">
      <div className="menubar">
        <div className="menubar-start">{start}</div>
        <div className="menubar-end">{end}</div>
      </div>
    </div>
  );
}
