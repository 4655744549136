import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../utils/api";
import { API_NO_AUTH } from "../utils/api";
import { removeToken } from "../utils/api";
import ToastMessageContext from "./ToastMessageContext";
import LogoutDialog from "../components/LogoutDialog";
import { useEffect } from "react";
import { getToken } from "../utils/api";
import { setToken } from "../utils/api";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const { showError } = useContext(ToastMessageContext);
  const [displayLogout, setDisplayLogout] = useState(false);
  const [loadingLogoutQuery, setLoadingLogoutQuery] = useState(false);
  const [userData, setUserData] = useState(null);
  const [responseLoginGoogle, setResponseLoginGoogle] = useState(null);

  /** ----QUERYS---- */
  const googleCallbackQuery = (searchParams) => {
    API_NO_AUTH.get(`auth/google-callback?${searchParams}`)
      .then((res) => {
        setResponseLoginGoogle(res.data);
        // Si ya esta registrado, redirigimos al home
        if (!res.data?.need_register) {
          updateUserData(res.data.user);
          setToken(res.data.access_token);
          navigate("/");
        }
      })
      .catch(() => {
        removeToken();
        setUserData(null);
        navigate("/auth/login");
      });
  };

  const logoutQuery = () => {
    setLoadingLogoutQuery(true);
    API.post("auth/logout")
      .then(() => {
        removeToken();
        setUserData(null);
        navigate("/auth/login");
      })
      .catch((err) => {
        showError(err.message);
      })
      .then(() => {
        setDisplayLogout(false);
        setLoadingLogoutQuery(false);
      });
  };

  const meQuery = () => {
    API.get("auth/me")
      .then((res) => {
        updateUserData(res.data);
      })
      .catch((err) => {
        showError(err.message);
      });
  };
  /** ----END QUERYS---- */

  const logout = () => {
    setDisplayLogout(true);
  };

  const updateUserData = (_userData) => {
    const _avatar_label = _userData.name.charAt(0);
    const _avatar = _userData.avatar;
    setUserData({ ..._userData, avatar: _avatar, avatar_label: _avatar_label });
  };

  const googleOAuthRedirect = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google-redirect`;
  };

  useEffect(() => {
    /** Si hacemos un refresh de la pagina y tenemos un token, traeremos los datos del usuario
     * Ademas los guardaremos en un estado global
     */
    if (getToken()) {
      meQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    logout,
    userData,
    updateUserData,
    googleOAuthRedirect,
    googleCallbackQuery,
    responseLoginGoogle,
  };

  return (
    <AuthContext.Provider value={data}>
      <LogoutDialog
        loading={loadingLogoutQuery}
        logoutQuery={logoutQuery}
        displayLogout={displayLogout}
        setDisplayLogout={setDisplayLogout}
      />
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };

export default AuthContext;
