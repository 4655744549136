const getErrorsStatus422 = (errors) => {
    /**
     * errors = Son los errores que recibimos desde la api con Laravel (de usar otro lenguaje o framework ver como devuelve los errores)
     * El formato recibido es un objeto con un array de mensajes. Por ej:
     * -----
     * email: ['error'],
     * name: ['error1', 'error2']
     * -----
     */

    let arrayErrors = [];

    // Recorremos las propiedades (key) del objeto
    Object.keys(errors).map((key) => {
        // Si propiedad posee mas de un elemento dentro, recorremos cada uno
        if (errors[key].length > 1) {
            errors[key].map((el) => {
                arrayErrors.push(el);
            });
        } else {
            // sino tomamos el unico valor que existe 
            arrayErrors.push(errors[key][0]);
        }
    });

    return arrayErrors;
}

export { getErrorsStatus422 };