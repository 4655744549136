import { Helmet } from "react-helmet-async";

export default function SeoTags({ pageTitle, pageDescription }) {
  return (
    <Helmet>
      <title>{`${process.env.REACT_APP_NAME} | ${pageTitle}`}</title>
      <meta
        name="description"
        content={pageDescription ? pageDescription : "Web Page"}
      />
      <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN_URL}`} />
    </Helmet>
  );
}
