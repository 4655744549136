import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { API_NO_AUTH } from "../../utils/api";
import { Message } from "primereact/message";
import SeoTags from "../../components/SeoTags";
import ResetPasswordForm from "../../components/Auth/ResetPasswordForm";
import LoadingPage from "../../components/Utils/LoadingPage";

export default function ResetPassword() {
  const [searchParams] = useSearchParams(); //obtenemos los parametros de la url
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);

  /** ----QUERYS---- */
  const verifyTokenQuery = () => {
    setLoading(true);
    API_NO_AUTH.post("auth/verify-reset-pass-token", {
      email: email,
      token: token,
    })
      .then(() => {})
      .catch((err) => {
        setErrorMsg(err.response.data.message);
      })
      .then(() => {
        setLoading(false);
      });
  };
  /** ----END QUERYS---- */

  useEffect(() => {
    verifyTokenQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <>
          <SeoTags
            pageTitle={"Nueva clave"}
            pageDescription={"Pagina para ingresar nueva contraseña de usuario"}
          />
          <div className="flex align-items-center justify-content-center mt-5 mb-5">
            <div className="surface-card p-4 shadow-2 border-round w-full md:w-5 m-5">
              {!errorMsg && (
                <>
                  <ResetPasswordForm emailValue={email} tokenValue={token} />
                </>
              )}

              {errorMsg && (
                <Message severity="error" text={errorMsg} className="w-full" />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
