import { Navigate, Outlet } from "react-router-dom";
import { getDecodedToken } from "../utils/api";

export default function AuthRoutes() {
  /** Solo obtendremos el token decodificado si el formato es correcto, pero no quiere decir que nuestra autentiacion sea verdadera.
   * Recien cuando hagamos una llamada a la api podremos ver si la autenticacion es correcta
   */
  const decodedToken = getDecodedToken();

  return decodedToken ? <Navigate to="/" /> : <Outlet />;
}
