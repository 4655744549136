import React from "react";
import { BrowserRouter } from "react-router-dom";
import { spanishLanguage } from "./utils/language";
import { ToastMessageProvider } from "./context/ToastMessageContext";
import { AuthContextProvider } from "./context/AuthContext";
import AppRoutes from "./routes/AppRoutes";

function App() {
  // Traduccion de palabras en primereact a español
  spanishLanguage();

  return (
    <BrowserRouter>
      <ToastMessageProvider>
        <AuthContextProvider>
          <AppRoutes />
        </AuthContextProvider>
      </ToastMessageProvider>
    </BrowserRouter>

  );
}

export default App;
