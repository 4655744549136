import { Link } from "react-router-dom";
import SeoTags from "../../components/SeoTags";
import LoginForm from "../../components/Auth/LoginForm";
import logo from "../../assets/img/logo.svg";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { Divider } from "primereact/divider";

export default function Login() {
  /**
   * Si se quiere usar el login con google en desarrollo, comentar las lineas <React.StrictMode> de index.js
   * Esto desactiva el modo estricto, que renderiza 2 veces los componentes y genera un error al llamado de la api de google.
   */

  const { googleOAuthRedirect } = useContext(AuthContext);

  return (
    <>
      <SeoTags
        pageTitle={"Login"}
        pageDescription={"Pagina de Inicio de Sesion"}
      />
      <div className="flex align-items-center justify-content-center mt-5 mb-5">
        <div className="surface-card p-4 shadow-2 border-round w-full md:w-5 m-5">
          <div className="text-center mt-5">
            <div className="loginLogo mb-3">
              <img src={logo} alt="Logo" />
            </div>
          </div>
          <LoginForm />

          <div className="text-right mb-5">
            <Link
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              to="/auth/forgot-password"
            >
              Olvide mi contraseña
            </Link>
          </div>

          <div className="text-center mt-2 mb-5">
            <Divider align="center" type="solid">
              O inicia sesión con
            </Divider>
            <div className="mb-4">
              <button
                type="button"
                className="login-with-google-btn"
                onClick={googleOAuthRedirect}
              >
                Google
              </button>
            </div>
          </div>

          <div className="text-center mb-5">
            <span className="text-600 font-medium line-height-3">
              No tenes cuenta?
            </span>
            <Link
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              to="/auth/register"
            >
              Creala desde aquí
            </Link>
          </div>

        </div>
      </div>
    </>
  );
}
