import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { API_NO_AUTH } from "../../utils/api";
import { getErrorsStatus422 } from "../../utils/errorsHandling";
import ToastMessageContext from "../../context/ToastMessageContext";
import FormErrorMessage from "../Utils/FormErrorMessage";
import MoreFormErrors from "../Utils/MoreFormErrors";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import useForm from "../../hooks/useForm";

/** STATIC VALUES */
const initialValues = {
  password: "",
  password_confirmation: "",
};

const initialFormErrors = {
  password: null,
  password_confirmation: null,
};
/** END STATIC VALUES */

export default function ResetPasswordForm(props) {
  /** CUSTOM FORM HOOK */
  const {
    handleFormChange,
    formData,
    formErrors,
    setFormErrors,
    moreFormErrors,
    setMoreFormErrors,
  } = useForm(
    { ...initialValues, email: props.emailValue, token: props.tokenValue },
    initialFormErrors
  );

  const { showError } = useContext(ToastMessageContext);
  const [loadingForm, setLoadingForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const validateForm = () => {
    let formIsValid = true;
    let _formErrors = formErrors;

    if (formData.password === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        password: "Campo requerido",
      };
    }

    if (formData.password_confirmation === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        password_confirmation: "Campo requerido",
      };
    }

    setFormErrors(_formErrors);
    return formIsValid;
  };

  /** ----QUERYS---- */
  const resetPassQuery = () => {
    setLoadingForm(true);
    API_NO_AUTH.post("auth/reset-password", formData)
      .then((res) => {
        setSuccessMessage(res.data.message);
      })
      .catch((err) => {
        const errors = err.response;
        if (errors.status === 422) {
          const errors422 = getErrorsStatus422(errors.data.errors);
          setMoreFormErrors(errors422);
        } else {
          showError(err.message);
        }
      })
      .then(() => {
        setLoadingForm(false);
      });
  };
  /** ----END QUERYS---- */

  const resetPass = (e) => {
    e.preventDefault();
    if (validateForm()) {
      resetPassQuery();
    }
  };

  return (
    <>
      {successMessage && (
        <div className="col-12 mb-4">
          <Message
            severity="success"
            text={successMessage}
            className="w-full"
          />
          <div className="text-center mt-5 mb-2">
            <Link
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              to="/auth/login"
            >
              Ir al login
            </Link>
          </div>
        </div>
      )}

      {!successMessage && (
        <form className="p-fluid grid" autoComplete="off" onSubmit={resetPass}>
          <div className="field text-center col-12 m-0">
            Ingrese su nueva contraseña para <strong>{props.emailValue}</strong>
          </div>
          <div className="field col-12 mt-4">
            <span className="p-float-label">
              <Password
                className={`w-full ${formErrors.password && "p-invalid"}`}
                value={formData.password}
                toggleMask
                feedback={false}
                name="password"
                onChange={handleFormChange}
              />
              <label>Contraseña</label>
            </span>
            <FormErrorMessage inputName="password" formErrors={formErrors} />
          </div>

          <div className="field col-12 mt-4">
            <span className="p-float-label">
              <Password
                className={`w-full ${
                  formErrors.password_confirmation && "p-invalid"
                }`}
                value={formData.password_confirmation}
                toggleMask
                feedback={false}
                name="password_confirmation"
                onChange={handleFormChange}
              />
              <label>Confirmar contraseña</label>
            </span>
            <FormErrorMessage
              inputName="password_confirmation"
              formErrors={formErrors}
            />
          </div>

          <MoreFormErrors errors={moreFormErrors} />

          <div className="field p-2 mt-4 w-full">
            <Button
              type="submit"
              loading={loadingForm}
              label="Cambiar contraseña"
              icon="pi pi-lock"
              className="w-full"
            />
          </div>
        </form>
      )}
    </>
  );
}
