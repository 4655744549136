import { Navigate } from "react-router-dom";
import { getDecodedToken } from "../utils/api";
import DashboardLayout from "../components/Layouts/DashboardLayout";

export default function PrivateRoutes() {
  /** Solo obtendremos el token decodificado si el formato es correcto, pero no quiere decir que nuestra autentiacion sea verdadera.
   * Recien cuando hagamos una llamada a la api podremos ver si la autenticacion es correcta
   */
  const decodedToken = getDecodedToken();

  return decodedToken ? <DashboardLayout /> : <Navigate to="/auth/login" />;
}
