import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import MoreFormErrors from "../Utils/MoreFormErrors";
import FormErrorMessage from "../Utils/FormErrorMessage";
import { API_NO_AUTH } from "../../utils/api";
import { getErrorsStatus422 } from "../../utils/errorsHandling";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import ToastMessageContext from "../../context/ToastMessageContext";
import useForm from "../../hooks/useForm";

/** STATIC VALUES */
const initialValues = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
};

const initialFormErrors = {
  name: null,
  email: null,
  password: null,
  password_confirmation: null,
};
/** END STATIC VALUES */

export default function RegisterForm() {
  /** CUSTOM FORM HOOK */
  const {
    handleFormChange,
    formData,
    formErrors,
    setFormErrors,
    moreFormErrors,
    setMoreFormErrors
  } = useForm(initialValues, initialFormErrors);

  const navigate = useNavigate();
  const { showSuccess, showError } = useContext(ToastMessageContext);
  const [loadingForm, setLoadingForm] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    let _formErrors = formErrors;
    if (formData.name === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        name: "Campo requerido",
      };
    }

    if (formData.email === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        email: "Campo requerido",
      };
    }

    if (formData.password === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        password: "Campo requerido",
      };
    }

    if (formData.password_confirmation === "") {
      formIsValid = false;
      _formErrors = {
        ..._formErrors,
        password_confirmation: "Campo requerido",
      };
    }

    setFormErrors(_formErrors);
    return formIsValid;
  };

  /** ----QUERYS---- */
  const registerQuery = () => {
    setLoadingForm(true);
    API_NO_AUTH.post("auth/register", formData)
      .then((res) => {
        showSuccess(res.data.message);
        navigate("/auth/login");
      })
      .catch((err) => {
        const errors = err.response;
        if (errors.status === 422) {
          const errors422 = getErrorsStatus422(errors.data.errors);
          setMoreFormErrors(errors422);
        } else {
          showError(err.message);
        }
      })
      .then(() => {
        setLoadingForm(false);
      });
  };
  /** ----END QUERYS---- */

  const register = (e) => {
    e.preventDefault();
    if (validateForm()) {
      registerQuery();
    }
  };

  return (
    <form className="p-fluid grid" autoComplete="off" onSubmit={register}>
      <div className="field col-12 mt-4">
        <span className="p-float-label">
          <InputText
            className={`w-full ${formErrors.name && "p-invalid"}`}
            name="name"
            onChange={handleFormChange}
            value={formData.name}
          />
          <label>Nombre y Apellido</label>
        </span>
        <FormErrorMessage inputName="name" formErrors={formErrors} />
      </div>

      <div className="field col-12 mt-4">
        <span className="p-float-label">
          <InputText
            className={`w-full ${formErrors.email && "p-invalid"}`}
            name="email"
            onChange={handleFormChange}
            value={formData.email}
          />
          <label>Email</label>
        </span>
        <FormErrorMessage inputName="email" formErrors={formErrors} />
      </div>

      <div className="field col-12 mt-4">
        <span className="p-float-label">
          <Password
            className={`w-full ${formErrors.password && "p-invalid"}`}
            value={formData.password}
            toggleMask
            feedback={false}
            name="password"
            onChange={handleFormChange}
          />
          <label>Contraseña</label>
        </span>
        <FormErrorMessage inputName="password" formErrors={formErrors} />
      </div>

      <div className="field col-12 mt-4">
        <span className="p-float-label">
          <Password
            className={`w-full ${
              formErrors.password_confirmation && "p-invalid"
            }`}
            value={formData.password_confirmation}
            toggleMask
            feedback={false}
            name="password_confirmation"
            onChange={handleFormChange}
          />
          <label>Confirmar contraseña</label>
        </span>
        <FormErrorMessage
          inputName="password_confirmation"
          formErrors={formErrors}
        />
      </div>

      <MoreFormErrors errors={moreFormErrors} />

      <div className="field p-2 mt-4 w-full">
        <Button
          type="submit"
          loading={loadingForm}
          label="Registrarme"
          icon="pi pi-user"
          className="w-full"
        />
      </div>
    </form>
  );
}
