import React from "react";
import { Tag } from "primereact/tag";
import CustomCard from "../Utils/CustomCard";
import DynamicTable from "../Utils/DynamicTable";

export default function AccountResultImport({ importResult }) {
  /** TABLE TEMPLATES */
  const motivoTemplate = (rowData) => {
    let motivos = [];
    // Si es un array, recorremos los motivos
    if (Array.isArray(rowData.motivo)) {
      rowData.motivo.map((data) => {
        // creamos div
        let div = (
          <div key={data[0]} className="text-sm">
            -{data[0]}
          </div>
        );
        // concatenamos el div
        motivos.push(div);
      });
    } else {
      // creamos div
      let div = <div className="text-sm">-{rowData.motivo}</div>;
      // concatenamos el div
      motivos.push(div);
    }

    return motivos;
  };

  const messageTemplate = (rowData) => {
    return <Tag severity="danger" value={rowData.message} />;
  };
  /** END TABLE TEMPLATES */

  /** COLUMNAS A RENDERIZAR EN TABLA */
  const columns = [
    {
      field: "message",
      header: "Mensaje",
      body: messageTemplate,
      sortable: false,
    },
    {
      field: "motivo",
      header: "Motivo/s",
      body: motivoTemplate,
      sortable: false,
    },
  ];

  /** END COLUMNS */

  return (
    <div className="mt-5">
      <div className="grid mb-4">
        <div className="col-12 md:col-6">
          <CustomCard
            title="Registros importados"
            quantity={importResult.registros_importados}
            iconClass="pi pi-check"
            bgIconClass="bg-green-100"
            iconColorClass="text-green-500"
          />
        </div>
        <div className="col-12 md:col-6">
          <CustomCard
            title="Registros no importados"
            quantity={importResult.registros_no_importados}
            iconClass="pi pi-times"
            bgIconClass="bg-red-100"
            iconColorClass="text-red-500"
          />
        </div>
      </div>

      <DynamicTable
        data={importResult.errores}
        columns={columns}
        loading={false}
      />
    </div>
  );
}
