import React, { useState, useContext } from "react";
import API from "../../utils/api";
import { Button } from "primereact/button";
import InputFile from "../../components/Utils/InputFile";
import LoadingSpinner from "../../components/Utils/LoadingSpinner";
import ToastMessageContext from "../../context/ToastMessageContext";
import AccountResultImport from "../../components/Accounts/AccountResultImport";

export default function AccountImportForm() {
  const { showError } = useContext(ToastMessageContext);
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [importResult, setImportResult] = useState(null);

  /** ----QUERYS---- */
  const importQuery = (formData) => {
    setLoading(true);

    API.post("accounts/import", formData)
      .then((res) => {
        setImportResult(res.data);
      })
      .catch((err) => {
        showError(err.message);
      })
      .then(() => {
        setImportFile(null);
        setLoading(false);
      });
  };
  /** ----END QUERYS---- */

  const importAgain = () => {
    setImportFile(null);
    setImportResult(null);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    /** Creamos formData */
    const formData = new FormData();
    formData.append("file", importFile);

    importQuery(formData);
  };
  return (
    <>
      {!importResult ? (
        <form onSubmit={handleSubmitForm}>
          {loading && (
            <div className="text-center mt-5">
              <div className="mb-5">Importando datos...</div>
              <LoadingSpinner />
            </div>
          )}

          {!loading && (
            <>
              <InputFile
                title="Seleccionar archivo"
                onChange={(e) => setImportFile(e.target.files[0])}
              />

              {importFile && (
                <Button
                  type="submit"
                  label="Importar"
                  icon="pi pi-upload"
                  className="p-button-success mr-2 mt-2"
                />
              )}

              <div className="mt-3">
                <span>
                  {importFile
                    ? `Archivo seleccionado: ${importFile.name}`
                    : "No selecciono archivo"}
                </span>
              </div>
            </>
          )}
        </form>
      ) : (
        <>
          <Button
            label="Volver a importar"
            icon="pi pi-arrow-left"
            className="p-button-success mr-2 mt-2"
            onClick={importAgain}
          />
          <AccountResultImport importResult={importResult} />
        </>
      )}
    </>
  );
}
