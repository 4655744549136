export default function MoreFormErrors({ errors }) {
  /**
   * errors = es el array de errores que armamos con lo que devuelve el backend
   */
  return (
    errors.length > 0 && (
      <div className="col-12">
        {errors.map((err) => {
          return (
            <span key={err} className="p-error block">{err}</span>
          );
        })}
      </div>
    )
  );
}
