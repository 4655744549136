import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

export default function ActionsToolbar({
  btnNewAction,
  btnImportAction,
  btnExportAction,
  loadingExport,
}) {
  const leftContents = (
    <div className="flex flex-wrap">
      <Button
        label="Nuevo"
        icon="pi pi-plus"
        className="mt-2 mr-2 p-button-primary"
        onClick={btnNewAction}
      />
      <Button
        label="Importar"
        icon="pi pi-file-excel"
        className="mt-2 mr-2 p-button-success"
        onClick={btnImportAction}
      />
      <Button
        loading={loadingExport}
        label="Exportar"
        icon="pi pi-file-excel"
        className="mt-2 mr-2 p-button-success"
        onClick={btnExportAction}
      />
    </div>
  );

  return <Toolbar left={leftContents} />;
}
